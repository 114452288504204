import axios from "axios";
import store from '@/store';
import router from "@/router";
import {alertController} from "@ionic/vue";

const env = process.env

const http = axios.create({baseURL: (env && env.VUE_APP_BASE_URL ? env.VUE_APP_BASE_URL : '') + (env && env.VUE_APP_API_PATH ? env.VUE_APP_API_PATH : '')});

http.interceptors.request.use((config) => {
    const token = store.state.token
    if(token && config && config.headers){
        config.headers.Authorization = `Bearer ${token}`
    }

    // TODO: set timeout and show connection error on fail (response is "undefined" on timeout)

    return config
},(error) => {

    return Promise.reject(error);
})

http.interceptors.response.use((response) => {
    return response
}, ({response}) => {
    if(response && response.status){
        const url = response.config && response.config.url ? response.config.url : ''
        if(response.status === 422 && !url.includes('invitee')){
            let message = ''
            Object.keys(response.data.errors).forEach((key) => {
                const val = response.data.errors[key]
                if(Array.isArray(val)){
                    message = message + val.join('') + '\n'
                }else{
                    message = message + val + '\n'
                }
            })

            alertController.create({
                message,
                buttons: [
                    "הבנתי"
                ]
            }).then(alert => {
                alert.present()
            })
        }else
        if(response.status === 401 && !store.getters.loggedOutAlerted){
            const {config} = response
            if(config && config.url && config.url.includes('auth/token')){
                return response
            }
            store.commit('loggedOutAlerted', true)
            store.dispatch('loggedOutAlert').then((answer) => {
                if(answer){
                    store.dispatch('logout').then(() => {
                        router.push({name: 'login'})
                    })
                }
            })
        }
    }
    return response
})

export default http
