
import {IonPage, IonContent, IonButton, IonLabel, IonInput, IonItem, IonList, alertController} from '@ionic/vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'Login',
    components: {IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonInput},
    data(){
        return {
            credentials: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        async login(){
            const {status} = await this.$store.dispatch('login', this.credentials)
            console.log(status)
            if(status === 200){
                await this.$router.push('/')
            }else if(status !== 422){
                const alert = await alertController.create({
                    message: 'שם משתמש ו\\או הסיסמה אינם נכונים, נסו שנית או לחצו על "איפוס סיסמה"',
                    buttons: [
                        'הבנתי'
                    ]
                })

                await alert.present()
            }
        }
    }
})
