<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="back">
                    <ion-icon slot="icon-only" :icon="icons.arrowForwardOutline"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>הוספת מוזמנים</ion-title>
            <ion-buttons slot="end">
                <ion-button v-if="inviteesToUpdate && inviteesToUpdate.length" @click="update">
                    סיום
                    <ion-icon slot="icon-only" :icon="icons.checkmarkOutline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh" :disabled="refreshDisabled">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <p>ביחרו מוזמנים לשיוך לשולחן זה, אם בחרתם מוזמן שמשוייך כבר לשולחן אחר השיוך ירד ממנו ויועבר לנוכחי.</p>
        <div v-if="invitees && invitees.length">
            <ion-item>
                <ion-label position="floating">חיפוש</ion-label>
                <ion-input v-model="searchQuery" type="text"></ion-input>
            </ion-item>
        </div>
        <ion-list v-if="filteredInvitees && filteredInvitees.length">
            <ion-list-header>סמנו מוזמנים לשיוך</ion-list-header>
            <template v-for="invitee in filteredInvitees" :key="invitee.id">
                <ion-item>
                    <ion-label>
                        <h2>{{invitee.firstName}} {{invitee.lastName}}</h2>
                        <h3>{{invitee.phone || invitee.email}}</h3>
                        <ion-badge color="warning" v-if="invitee.tableSeating">מוזמן זה משוייך לשולחן ({{invitee.tableSeating.name}})</ion-badge>
                    </ion-label>
                    <ion-checkbox slot="end" @update:modelValue="toggleInvitee($event,invitee)"></ion-checkbox>
                </ion-item>
            </template>
        </ion-list>
    </ion-content>
</template>

<script>
import {defineComponent} from "vue";
import {
    IonBadge,
    IonButton,
    IonButtons, IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonRefresher, IonRefresherContent,
    IonTitle,
    IonToolbar, loadingController,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";

export default defineComponent({
    name: "ChooseInvitees",
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonCheckbox,
        IonRefresher,
        IonRefresherContent,
        IonInput,
        IonBadge
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline
            },
            inviteesToUpdate: [],
            invitees: [],
            refreshDisabled: true,
            searchQuery: ''
        }
    },
    computed: {
        filteredInvitees(){
            return this.invitees.filter((invitee) => {
                return (invitee.firstName && invitee.firstName.includes(this.searchQuery)) ||
                        (invitee.lastName && invitee.lastName.includes(this.searchQuery)) ||
                        (invitee.phone && invitee.phone.includes(this.searchQuery)) ||
                        (invitee.email && invitee.email.includes(this.searchQuery))
            })
        }
    },
    methods: {
        async back(){
            await modalController.dismiss()
        },
        async update(){
            await modalController.dismiss(JSON.parse(JSON.stringify(this.inviteesToUpdate)), 'update')
        },
        async doRefresh(e){
            await this.loadInvitees(false)
            e.target.complete()
        },
        async loadInvitees(){

            const loader = await loadingController.create({
                message: 'טוען'
            })
            await loader.present()

            const {data} = await this.$http.get('invitees', {params: {with: 'tableSeating'}})

            await loader.dismiss()

            if(data.invitees){
                this.invitees = data.invitees
            }

        },
        toggleInvitee(e, invitee){
            const existsIndex = this.inviteesToUpdate.findIndex(inv => {
                return inv.id === invitee.id
            })
            if(e && existsIndex === -1){
                this.inviteesToUpdate.push(invitee)
            }else if(existsIndex !== -1){
                this.inviteesToUpdate.splice(existsIndex, 1)
            }
        }
    },
    mounted() {
        this.loadInvitees()
    }
})
</script>

<style scoped>

</style>
