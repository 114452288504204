import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03ba6ede"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ei-close-button",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
  ]))
}