
import {
    IonPage,
    IonContent,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption, alertController, toastController
} from '@ionic/vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'PasswordReset',
    components: {IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption},
    data(){
        return {
            email: '',
            phone: '',
            otpType: 'email'
        }
    },
    methods: {
        async passwordReset(){
            let success = false
            if(this.otpType === 'email'){
                success = await this.$store.dispatch('sendResetPasswordOtp', {email: this.email})
            }else if(this.otpType === 'phone'){
                success = await this.$store.dispatch('sendResetPasswordOtp', {phone: this.phone})
            }

            if(success){
                const {otp, password} = await this.resetDialog()
                if(this.otpType === 'email'){
                    if(await this.$store.dispatch('resetPassword', {email: this.email, otp, password})){
                        await (await toastController.create({
                            message: 'סיסמה שונתה בהצלחה!',
                            color: 'success',
                            duration: 2000
                        })).present()
                        await this.$router.push('/')
                    }
                }else if(this.otpType === 'phone'){
                    if(await this.$store.dispatch('resetPassword', {phone: this.phone, otp, password})){
                        await (await toastController.create({
                            message: 'סיסמה שונתה בהצלחה!',
                            color: 'success',
                            duration: 2000
                        })).present()
                        await this.$router.push('/')
                    }
                }
            }else{
                await (await alertController.create({
                    message: 'התרחשה תקלה, בידקו את חיבור האינטרנט או נסו שנית מאוחר יותר',
                    buttons: [
                        'הבנתי'
                    ]
                })).present()
            }
        },
        async resetDialog(values?: any): Promise<any>{
            const type = this.otpType === 'email' ? 'דוא&quot;ל': 'מסרון'
            const alert = await alertController.create({
                message: `כיתבו כאן את הקוד שקיבלתם ב${type} ואת הסיסמה החדשה`,
                inputs: [
                    {
                        label: 'קוד אימות',
                        type: 'tel',
                        name: 'otp',
                        placeholder: '123456',
                        value: values && values.otp ? values.otp : ''
                    },
                    {
                        label: 'הסיסמה החדשה שלכם',
                        placeholder: 'הסיסמה החדשה שלכם',
                        type: 'password',
                        name: 'password'
                    },
                    {
                        label: 'אימות סיסמה',
                        placeholder: 'אימות סיסמה (הקישו את הסיסמה בשנית)',
                        type: 'password',
                        name: 'passwordConfirmation'
                    }
                ],
                buttons: [
                    'אישור'
                ],
                backdropDismiss: false
            })

            await alert.present()
            const {data} = await alert.onDidDismiss()
            if(data && data.values){
                if(data.values.password === data.values.passwordConfirmation){
                    return data.values
                }

                const alert = await alertController.create({
                    message: 'אימות הסיסמה לא תואם את הסיסמה, יש להקיש בשנית',
                    buttons: [
                        'הבנתי'
                    ]
                })

                await alert.present()
                await alert.onDidDismiss()

                return await this.resetDialog(data.values)
            }
            return await this.resetDialog()
        }
    }
})
