
import {defineComponent} from "vue";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    modalController, IonLabel, IonItemSliding, IonItemOption, IonItemOptions, IonFab, IonFabButton, loadingController
} from "@ionic/vue";
import {addOutline, arrowForwardOutline, checkmarkOutline, closeOutline, trashOutline} from "ionicons/icons";
import ChooseInvitees from "@/modals/ChooseInvitees.vue";

export default defineComponent({
    name: "EditTableSeating",
    props: {
        input: {
            required: false,
            default: null
        }
    },
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonList,
        IonItem,
        IonItemSliding,
        IonItemOptions,
        IonItemOption,
        IonLabel,
        IonFab,
        IonFabButton
    },
    computed: {
        tableName(){
            if(this.table && this.table.name){
                if(this.table.name.includes('שולחן')){
                    return `ל${this.table.name}`
                }
                return `לשולחן ${this.table.name}`
            }
            return ''
        }
    },
    data(){
        const table: any = {}
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline,
                closeOutline,
                trashOutline,
                addOutline
            },
            table,
        }
    },
    methods: {
        async back(){
            await modalController.dismiss(null, 'back', 'table-seatings')
        },
        async save(){
            const inviteesIds = this.table.invitees.map((inv: any) => inv.id)
            const loader = await loadingController.create({
                message: 'מעדכן שולחן'
            })
            await loader.present()
            await this.$http.post(`seatings/${this.table.id}/sync-invitees`, {invitees: inviteesIds})
            await loader.dismiss()
            await modalController.dismiss(null, 'save', 'table-seatings')
        },
        removeInvitee(invitee: any){
            const index = this.table.invitees.findIndex((inv: any) => inv.id === invitee.id)
            if(index > -1){
                this.table.invitees.splice(index, 1)
            }
        },
        async addInvitees(){
            const dialog = await modalController.create({
                component: ChooseInvitees
            })

            await dialog.present()
            const {role, data} = await dialog.onDidDismiss()
            if(role === 'update'){
                if(Array.isArray(data)){
                    data.forEach(invitee => {
                        const existingIndex = this.table.invitees.findIndex((inv: any) => inv.id === invitee.id)
                        if(existingIndex === -1){
                            this.table.invitees.push(invitee)
                        }
                    })
                }
            }
        }
    },
    beforeMount() {
        if(this.input){
            this.table = Object.assign({}, this.input)
        }
    }
})
