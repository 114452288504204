
import {IonPage, IonContent, IonCardContent, IonCard, IonIcon, IonRow, IonCol} from '@ionic/vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'EventTypeChoose',
    components: {IonContent, IonPage, IonCard, IonCardContent, IonIcon, IonRow, IonCol},
    data(){
        return {
            userInfo: {
                eventType: ''
            }
        }
    },
    methods: {
        async choose(eventType: string){
            await this.$store.dispatch('updateAccount', {eventType})
            await this.$router.replace('/tabs/design')
        }
    }
})
