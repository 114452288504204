
import {defineComponent} from "vue";
import {IonButton, IonContent, IonPage} from "@ionic/vue";
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css"
import "swiper/modules/effect-fade/effect-fade.min.css"
import SwiperCore, {EffectFade, Autoplay} from 'swiper';
SwiperCore.use([EffectFade, Autoplay]);

export default defineComponent({
    name: 'Intro',
    components: {IonPage, IonContent, Swiper, IonButton, SwiperSlide}
})
