
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet} from '@ionic/vue';
import {diamondOutline, timerOutline, listOutline, walletOutline} from 'ionicons/icons';

export default {
    name: 'Tabs',
    components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet},
    setup() {
        return {
            diamondOutline,
            timerOutline,
            listOutline,
            walletOutline
        }
    }
}
