
import {
    IonApp,
    IonButtons,
    IonHeader,
    IonMenu,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    IonContent,
    IonIcon
} from '@ionic/vue';
import {defineComponent} from 'vue';
import EiMenuButton from "@/components/EiMenuButton.vue";
import {menuController} from "@ionic/vue";
import { logoFacebook, logoYoutube, logoTwitter } from "ionicons/icons"
import EiCloseButton from "@/components/EiCloseButton.vue";

export default defineComponent({
    name: 'App',
    components: {
        EiCloseButton,
        EiMenuButton,
        IonApp,
        IonRouterOutlet,
        IonMenu,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonContent,
        IonIcon
    },
    computed: {
        showToolbar(){
            return this.$store.getters.showToolbar
        }
    },
    methods: {
        closeMenu(){
            menuController.close()
        }
    },
    setup(){
        return {
            icons: {
                logoFacebook,
                logoYoutube,
                logoTwitter
            }
        }
    },
    mounted() {
        this.$store.dispatch('loadData')
    }
});
