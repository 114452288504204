
import {menuController} from "@ionic/vue";
import {defineComponent} from "vue";

export default defineComponent({
    name: "EiMenuButton",
    methods: {
        toggleMenu(){
            menuController.toggle()
        }
    }
})
