
import {defineComponent} from "vue";
import {
    IonButton,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid, IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow, modalController
} from "@ionic/vue";
import {mapGetters} from "vuex";
import {addOutline} from "ionicons/icons";
import EditTableSeating from "@/modals/EditTableSeating.vue";
import TableSeating from "@/interfaces/TableSeating";
import EditTable from "@/modals/EditTable.vue";

export default defineComponent({
    name: 'TableSeatings',
    components: {
        IonPage,
        IonContent,
        IonGrid,
        IonRow,
        IonCol,
        IonRefresher,
        IonRefresherContent,
        IonFab,
        IonFabButton,
        IonIcon,
        IonButton
    },
    computed: {
        ...mapGetters(['tableSeating'])
    },
    setup(){
        return {
            icons: {
                addOutline
            }
        }
    },
    methods: {
        tableTypeTrans(type: string){
            const types: any = {
                square: 'מרובע',
                knights: 'אבירים',
                round: 'עגול'
            }
            return types[type] || ''
        },
        async editTable(table: any = null){
            const modal = await modalController.create({
                component: EditTable,
                componentProps: {
                    input: table
                }
            })

            await modal.present()

            const {role, data} = await modal.onDidDismiss()
            if(role === 'save'){
                await this.$store.dispatch('tableSeatingSave', data)
                await this.loadData()
            }else if(role === 'remove'){
                await this.$store.dispatch('tableSeatingRemove', data)
                await this.loadData()
            }
        },
        async editTableSeatings(table: TableSeating){
            const modal = await modalController.create({
                component: EditTableSeating,
                componentProps: {
                    input: table
                },
                id: 'table-seatings'
            })

            await modal.present()

            await modal.onDidDismiss()

            await this.loadData()
        },
        async loadData(){
            await this.$store.dispatch('tableSeatingLoad')
        },
        async doRefresh(e: any){
            await this.loadData()
            e.target.complete()
        },
    },
    async mounted(){
        await this.loadData()
    }
})
