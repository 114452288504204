
import {IonPage, IonContent, alertController, IonButton, IonList, IonItem, IonLabel, IonInput} from '@ionic/vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'Register',
    components: {IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonInput},
    data(){
        return {
            user: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                phone: ''
            },
            passwordConfirmation: null
        }
    },
    methods: {
        async register(){
            if(this.user.password == this.passwordConfirmation){
                const {data, status} = await this.$store.dispatch('register', {user: this.user})
                if(status !== 200 && !data.errors){
                    await (await alertController.create({
                        message: "התרחשה שגיאה כללית, בידקו את חיבור האינטרנט שלכם או נסו שנית מאוחר יותר.",
                        buttons: [
                            "הבנתי"
                        ]
                    })).present()
                }else{
                    await this.$router.push('/choose-event')
                }
            }else{
                const alert = await alertController.create({
                    message: "סיסמה לא מתאימה לאימות הסיסמה",
                    buttons: ['הבנתי']
                })

                await alert.present()

                const result = alert.onDidDismiss()
                console.log(result)
            }
        }
    }
})
