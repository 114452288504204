
import {closeOutline} from "ionicons/icons"
import {IonIcon} from "@ionic/vue";
import {defineComponent} from "vue";

export default defineComponent({
    name: "EiCloseButton",
    components: {IonIcon},
    setup(){
        return {
            closeOutline
        }
    }
})
